import * as React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Header from "../components/Header"
import * as moment from "moment"
import styled from "styled-components"
import {
  Headline1,
  Subhead2,
  Body1,
  Headline2,
} from "../components/rmwc/Typography"
import { Container } from "../components/Container"
import SubscribeButton from "../components/ButtonSubscribe"
import PageWrapper from "../pageWrapper"
import { useCurrentUser } from "../hooks/useCurrentUser"
import useFirestore from "../hooks/useFirestore"
import { useSet } from "../hooks/useMutation"
import { IconButton } from "../components/rmwc/IconButton"
import { Snackbar, SnackbarAction } from "@rmwc/snackbar"
import { NOW, DATE_FORMAT } from "../core/constants"
import ComicCover from "../components/ComicCover"

export const baseImagePath =
  "https://storage.googleapis.com/sublist-vgraphql.appspot.com/images"

export const query = graphql`
  query ComicQuery($seriesCode: String!, $today: Float!) {
    aftercutoffIssues: allIssues(
      filter: {
        seriesCode: { eq: $seriesCode }
        shipDate: { gt: $today }
        printDate: { lt: $today }
      }
    ) {
      group(field: issue) {
        nodes {
          title
          seriesCode
          coverArtist
          issue
          stockNumber
          variant
          cover
          issue
          printDate
          shipDate
          description

          image {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    upcomingIssues: allIssues(
      filter: {
        seriesCode: { eq: $seriesCode }
        shipDate: { gt: $today }
        printDate: { gt: $today }
      }
    ) {
      group(field: issue) {
        nodes {
          title
          seriesCode
          coverArtist
          issue
          stockNumber
          variant
          cover
          issue
          printDate
          shipDate
          description

          image {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export const TitleSection = styled.div`
  height: 144px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const ReleaseWrapper = styled.div`
  margin-bottom: 24px;
`

const ReleaseHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 8px;
`

const ReleaseContent = styled.div<{ afterCutoff?: boolean }>`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  opacity: ${p => p.afterCutoff && 0.5};

  @media (max-width: 420px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`

const Description = styled(Body1)`
  margin-bottom: 20px;
  line-height: 32px;
`

const Covers = styled.div`
  margin-bottom: 8px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  @media (max-width: 420px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
`

const ContentWrapper = styled.div`
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  /* gap: 16px; */
`

const Content = styled.div`
  /* grid-column-start: span 2; */
`

export const PullContext = React.createContext(null)

export default ({ data }) => {
  let comic
  const upcomingReleases = data.upcomingIssues.group
  const aftercutoffIssues = data.aftercutoffIssues.group

  if (upcomingReleases.length) {
    comic = upcomingReleases[0].nodes[0]
  } else {
    comic = aftercutoffIssues[0].nodes[0]
  }
  const { currentUser } = useCurrentUser()
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)

  let pullId = null
  if (currentUser) {
    pullId = `${currentUser.uid}-${comic.seriesCode}`
  }

  const { data: pull, loading } = useFirestore({
    collection: "pulls",
    find: pullId,
  })

  return (
    <PageWrapper>
      <Header />
      <SEO title={`${comic.title} preview`} />
      <Container>
        <PullContext.Provider
          value={{ pull, loading, snackbarOpen, setSnackbarOpen }}
        >
          <TitleSection>
            <div>
              <Headline1 color="primary">{comic.title}</Headline1>
              {!loading && (
                <>
                  {pull?.active && (
                    <Body1>
                      You added to your list on{" "}
                      {moment(pull.createdOn).format("MMMM Do, YYYY")}
                    </Body1>
                  )}
                  {pull && !pull.active && (
                    <Body1>
                      You cancelled on{" "}
                      {moment(pull.cancelledOn).format("MMMM Do, YYYY")}
                    </Body1>
                  )}
                </>
              )}
            </div>
            <SubscribeButton
              seriesCode={comic.seriesCode}
              title={comic.title}
              currentUser={currentUser}
            />
          </TitleSection>

          {aftercutoffIssues.map(release => (
            <Release issues={release.nodes} />
          ))}

          {upcomingReleases.map(release => (
            <Release issues={release.nodes} />
          ))}
        </PullContext.Provider>
      </Container>
      <Snackbar
        open={snackbarOpen}
        onClose={evt => setSnackbarOpen(false)}
        message="This issue is after the cutoff date and cannot be modified."
        action={<SnackbarAction label="OK" />}
      />
    </PageWrapper>
  )
}

export const Release: React.FC<{ issues: any[] }> = ({ issues }) => {
  const firstIssue = issues[0]
  const afterCutoff = moment(NOW).valueOf() > firstIssue.printDate
  const { pull, loading } = React.useContext(PullContext)

  let pullCreatedAfterCutoff = false
  if (pull?.active && pull?.createdOn > firstIssue.printDate) {
    pullCreatedAfterCutoff = true
  }

  let selectedCoverCount: number = 0
  if (pull?.active && pull?.covers) {
    const selectedCovers = pull.covers[firstIssue.issue]
    if (selectedCovers) {
      selectedCoverCount = Object.values(selectedCovers).reduce(
        (cover: number, inc: number) => cover + inc,
        0
      ) as number
    }
  }

  if (loading) return null

  return (
    <ReleaseWrapper>
      <ReleaseHeader>
        <Headline2>
          {firstIssue.title} #{firstIssue.issue}
        </Headline2>

        <Subhead2>
          Release: {moment(firstIssue.shipDate).format("MM/DD/YYYY")}
        </Subhead2>
        <Subhead2 color={afterCutoff ? "error" : "success"}>
          {afterCutoff ? "After " : ""} Cuttoff:{" "}
          {moment(firstIssue.printDate).format("MM/DD/YYYY")}
        </Subhead2>
      </ReleaseHeader>

      <ReleaseContent afterCutoff={afterCutoff}>
        <ContentWrapper>
          <Content>
            <Description>{firstIssue.description}</Description>
            {pull?.active &&
              selectedCoverCount != 0 &&
              !pullCreatedAfterCutoff && (
                <Body1 color="primary">
                  {selectedCoverCount} copies will be shipped
                </Body1>
              )}
            {pull?.active &&
              selectedCoverCount == 0 &&
              !pullCreatedAfterCutoff && (
                <Body1 color="error">
                  If you don't select a cover by{" "}
                  {moment(firstIssue.printDate).format("MMM Do, YYYY")} you will
                  be skipping this release.
                </Body1>
              )}
            {pull?.active && pullCreatedAfterCutoff && (
              <Body1 color="error">
                You signed up for this title after the cutoff for this issue.
              </Body1>
            )}
            <br />
            <Covers>
              {issues.map(issue => (
                <>
                  {pull?.active && !pullCreatedAfterCutoff && (
                    <CoverSelector
                      afterCutoff={afterCutoff}
                      key={issue.stockNumber}
                      issue={issue}
                    />
                  )}

                  {(!pull?.active || pullCreatedAfterCutoff) && (
                    <ComicCover
                      image={
                        issue.image?.childImageSharp?.fluid ||
                        `${baseImagePath}/${issue.stockNumber}.jpg`
                      }
                      primaryText={`Cover ${mapCoverToLetter(issue.cover)}`}
                      secondaryText={issue.coverArtist}
                    />
                  )}
                </>
              ))}
            </Covers>
          </Content>
        </ContentWrapper>
      </ReleaseContent>
    </ReleaseWrapper>
  )
}

const CoverSelectorWrapper = styled.div``

const QuantitySelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const CoverSelector: React.FC<{
  afterCutoff: boolean
  issue: any
}> = ({ afterCutoff, issue }) => {
  const { pull, loading, setSnackbarOpen } = React.useContext(PullContext)
  const pullId = `${pull.uid}-${pull.seriesCode}`
  const issueNumber = issue.issue
  const cover = issue.cover

  let selectedQuantity = 0
  if (pull.covers) {
    if (pull.covers[issueNumber]) {
      selectedQuantity = pull.covers[issueNumber][cover]
    } else {
      selectedQuantity = pull.covers.default[cover]
    }
  }

  const [setCover] = useSet({
    collection: "pulls",
    data: {
      covers: {
        default: {
          [cover]: selectedQuantity > 0 ? 0 : 1,
        },
        [issueNumber]: {
          [cover]: selectedQuantity > 0 ? 0 : 1,
        },
      },
    },
  })

  const [increaseCover] = useSet({
    collection: "pulls",
    data: {
      covers: {
        default: {
          [cover]: selectedQuantity + 1,
        },
        [issueNumber]: {
          [cover]: selectedQuantity + 1,
        },
      },
    },
  })

  const [decreaseCover] = useSet({
    collection: "pulls",
    data: {
      covers: {
        default: {
          [cover]: selectedQuantity - 1,
        },
        [issueNumber]: {
          [cover]: selectedQuantity - 1,
        },
      },
    },
  })

  const handleSelectCover = () => {
    if (!afterCutoff) {
      setCover(pullId)
    } else {
      setSnackbarOpen(true)
    }
  }

  const handleIncreaseCover = pullId => {
    if (!afterCutoff) {
      increaseCover(pullId)
    } else {
      setSnackbarOpen(true)
    }
  }

  const handleDecreaseCover = pullId => {
    if (!afterCutoff) {
      decreaseCover(pullId)
    } else {
      setSnackbarOpen(true)
    }
  }

  return (
    <CoverSelectorWrapper>
      <div onClick={handleSelectCover}>
        <ComicCover
          image={
            issue.image?.childImageSharp?.fluid ||
            `${baseImagePath}/${issue.stockNumber}.jpg`
          }
          primaryText={`Cover ${mapCoverToLetter(issue.cover)}`}
          secondaryText={issue.coverArtist}
          selected={selectedQuantity}
        />
      </div>

      {selectedQuantity > 0 && (
        <QuantitySelector>
          <IconButton
            icon="remove_circle"
            onClick={() => handleDecreaseCover(pullId)}
          />
          <Headline2>{selectedQuantity}</Headline2>
          <IconButton
            icon="add_circle"
            onClick={() => handleIncreaseCover(pullId)}
          />
        </QuantitySelector>
      )}
    </CoverSelectorWrapper>
  )
}

function mapCoverToLetter(cover) {
  return String.fromCharCode(cover + 97).toUpperCase()
}
