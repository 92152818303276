import * as React from "react"
import {
  IconButton as MaterialIconButton,
  IconButtonProps,
} from "@rmwc/icon-button"
import "@material/icon-button/dist/mdc.icon-button.css"
import styled from "styled-components"
import { theme } from "../../../theme"

interface Props {
  color?: string
  onClick?: () => void
}

const StyledMaterialButton = styled(MaterialIconButton)`
  &&& {
    color: ${p => p.color || theme.primary};
  }
`

export const IconButton: React.FC<IconButtonProps & Props> = props => {
  return <StyledMaterialButton {...props} />
}
