import * as React from "react"
import styled from "styled-components"
import { Headline1 } from "./rmwc/Typography"
import { Icon } from "@rmwc/icon"
import { Link } from "gatsby"
import { Container } from "./Container"
import { List } from "@rmwc/list"

export const StyledList = styled.div`
  padding: 16px;
  padding-top: 74px;
  padding-right: 48px;
  width: 325px;
`
const HeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledHeadline = styled(Headline1)`
  font-size: 24px;
  color: var(--mdc-theme-primary);
`

export const ListHeadline: React.FC<{ meta?: React.ReactNode }> = props => {
  const { children, meta } = props
  return (
    <HeadlineWrapper>
      <StyledHeadline>{children}</StyledHeadline>
      {meta}
    </HeadlineWrapper>
  )
}

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 0 16px;
  margin-top: 42px;

  @media (max-width: 420px) {
    margin-top: 8px;
  }
`

export const SearchInput = styled.input`
  background-color: transparent;
  flex: 1;
  border: none;
  font-size: 14px;
  height: 100%;
  color: var(--mdc-theme-primary);

  &::placeholder {
    color: var(--mdc-theme-text-hint-on-light);
  }

  &:focus {
    outline: none;
  }
`

export const SearchDate = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
`

export const SearchIcon = styled(Icon)`
  color: var(--mdc-theme-text-hint-on-light);
`

export const ListWrapper = styled.div`
  margin-top: 20px;
`
export const ListItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  font-size: 14px;
`

export const Text = styled.div`
  font-size: 14px;
  color: var(--mdc-theme-text-secondary-on-background);
`

export const FlexContainer = styled(Container)<{
  justify?: string
  direction?: string
}>`
  display: flex;
  flex: 1;
  justify-content: ${p => p.justify};
  flex-direction: ${p => p.direction};
  margin-bottom: 16px;
`

export const FlexList = styled(List)`
  flex: 1;
`

export const ListItemMetaGap = styled.div`
  & > * {
    margin-left: 48px;
  }
`
