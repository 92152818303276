import * as React from "react"
import { firestore } from "../core/firebase"

interface FirestoreProps {
  collection: string
  where?: any[]
  find?: string
  key?: any
  orderBy?: string[]
  limit?: number
  update?: any
}

const useFirestore = (props: FirestoreProps) => {
  const { collection, find, key, where, limit, orderBy, update } = props
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState<any>({})

  let unsubscribe

  React.useEffect(() => {
    let collectionRef = firestore.collection(collection)

    if (find) {
      collectionRef = collectionRef.doc(find)
      unsubscribe = collectionRef.onSnapshot(doc => {
        if (doc.data()) {
          setData({ ...doc.data(), id: doc.id })
          setLoading(false)
        } else {
          setData(null)
          setLoading(false)
        }
      })
    } else {
      if (where) {
        where.forEach(whereClause => {
          collectionRef = collectionRef.where(
            whereClause[0],
            whereClause[1],
            whereClause[2]
          )
        })
      }

      if (limit) {
        collectionRef = collectionRef.limit(limit)
      }

      if (orderBy) {
        collectionRef = collectionRef.orderBy(orderBy[0], orderBy[1] || "asc")
      }

      unsubscribe = collectionRef.onSnapshot(snapshot => {
        setData(
          snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        )
        setLoading(false)
      })
    }

    return () => {
      unsubscribe()
    }
  }, [find, update, key])

  return { data, loading }
}

export const useFirestoreRef = collection => {
  let collectionRef = firestore.collection(collection)
  return collectionRef
}

export default useFirestore
