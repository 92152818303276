import * as React from "react"
import LazyLoad from "react-lazyload"
import { Subhead2 } from "./rmwc/Typography"
import styled from "styled-components"
import { Subhead1 } from "./rmwc/Typography"
import Img from "gatsby-image"

const CoverWrapper = styled.div`
  position: relative;
`

const CroppedImage = styled(Img)`
  height: 320px;

  @media (max-width: 420px) {
    height: 200px;
  }

  img {
    object-position: top center !important;
  }
`
const CroppedImg = styled.img`
  height: 320px;
  width: 100%;
  object-fit: contain;

  @media (max-width: 420px) {
    height: 200px;
  }

  img {
    object-position: top center !important;
  }
`

export const ComicDetailContent = styled.div`
  height: 120px;
  padding: 16px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ComicTitle = styled(Subhead1)`
  text-align: center;

  @media (max-width: 420px) {
    height: auto;
    padding: 8px;
    font-size: 14px;
  }
`

const CoverSelectionState = styled.div<{ selected: number }>`
  z-index: 1;

  box-shadow: ${props =>
    props.selected ? `inset 0 0 0 8px ${props.theme.primary}` : "none"};
  width: 100%;
  height: 320px;
  position: absolute;
  cursor: pointer;
`

interface Props {
  primaryText: string
  secondaryText: string
  image: any
  selected?: number
}

const ComicCover: React.FC<Props> = props => {
  const { primaryText, secondaryText, image, selected } = props

  return (
    <CoverWrapper>
      <LazyLoad height={320} style={{ position: "relative" }}>
        <CoverSelectionState selected={selected} />

        {typeof image === "string" && (
          <CroppedImg src={image} alt={primaryText} />
        )}

        {typeof image !== "string" && (
          <CroppedImage fluid={image} alt={primaryText} fadeIn={true} />
        )}
      </LazyLoad>

      <ComicDetailContent>
        <ComicTitle color="grey700">{primaryText}</ComicTitle>

        <Subhead2 color="grey500">{secondaryText}</Subhead2>
      </ComicDetailContent>
    </CoverWrapper>
  )
}

export default ComicCover
