import * as React from "react"
import { useCurrentUser } from "../hooks/useCurrentUser"
import { auth } from "../core/firebase"
import { Menu } from "./rmwc/Menu"
import { MenuItem } from "@rmwc/menu"
import { Link } from "@reach/router"
import { IconButton } from "./rmwc/IconButton"

export function HeaderSignedIn(props) {
  const { loading, currentUser } = useCurrentUser()
  if (loading) return null

  return (
    <Menu
      handle={<IconButton icon="account_circle" color="white" />}
      hoistToBody={true}
    >
      <Link to={`/user/profile`}>
        <MenuItem>Profile</MenuItem>
      </Link>

      {currentUser.admin && (
        <Link to="/admin/users">
          <MenuItem>Admin</MenuItem>
        </Link>
      )}

      <MenuItem onClick={() => auth.signOut()}>Sign out</MenuItem>
    </Menu>
  )
}
