import * as React from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "./theme"
import { Elements, StripeProvider } from "react-stripe-elements"
import { StripeHookProvider } from "./hooks/StripeHookProvider"
import { STRIPEKEY, VERSION, NOW, DATE_FORMAT } from "./core/constants"
import * as moment from "moment"
import styled from "styled-components"

const NowDisplay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  padding: 8px;
  z-index: 100;
`

const PageWrapper = ({ children }) => {
  return (
    <StripeProvider apiKey={STRIPEKEY}>
      <Elements>
        <StripeHookProvider>
          <ThemeProvider theme={theme}>
            <>
              {VERSION == "DEV" && (
                <NowDisplay>{moment(NOW).format(DATE_FORMAT)}</NowDisplay>
              )}
              {children}
            </>
          </ThemeProvider>
        </StripeHookProvider>
      </Elements>
    </StripeProvider>
  )
}

export default PageWrapper
