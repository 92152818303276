import * as React from 'react'
import { useContext } from 'react'
import { injectStripe } from 'react-stripe-elements'

const Context = React.createContext(null)
export const useStripe = () => useContext(Context)

const HookProvider = ({ children, stripe }) => {
  return <Context.Provider value={stripe}>{children}</Context.Provider>
}

export const StripeHookProvider = injectStripe(HookProvider)
