import styled from "styled-components"

const TypographyBase = styled.div`
  color: ${props => props.theme[props.color]};
`

export const Headline1 = styled(TypographyBase)`
  font-size: 45px;
  letter-spacing: -0.03em;
  font-weight: 100;
`

export const Headline2 = styled(TypographyBase)`
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 400;
`

//////////////////////
/// SUB HEADS
//////////////////////

export const Subhead1 = styled(TypographyBase)`
  color: ${props => props.theme[props.color]};
  font-size: 18px;
  font-weight: 700;
`
export const Subhead2 = styled(TypographyBase)`
  color: ${props => props.theme[props.color]};
  font-size: 16px;
  font-weight: 400;
`

//////////////////////
// BODY
//////////////////////

export const Body1 = styled(TypographyBase)`
  color: ${props => props.theme[props.color]};
  font-size: 16px;
`
export const Body2 = styled(TypographyBase)`
  color: ${props => props.theme[props.color]};
  font-size: 14px;
  font-weight: 700;
`

export const BodySmall = styled(TypographyBase)`
  color: ${props => props.theme[props.color]};
  font-size: 13px;
`

export const Caption = styled(TypographyBase)`
  color: ${props => props.theme[props.color]};
  font-size: 10px;
`

export const Warning = styled(Body1)`
  display: inline-block;
  background-color: ${props => props.theme.error};
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
`
