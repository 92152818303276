import * as React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  background-color: ${props => props.theme.grey200};
  background-image: ${props =>
    props.disabled
      ? "none"
      : "linear-gradient(135deg, #835bee 0%, #2e7cd8 100%)"};
  box-shadow: ${props =>
    props.disabled ? "none" : "0 8px 14px 0 rgba(131, 90, 238, 0.32)"};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.disabled ? props.theme.grey300 : "white")};
  height: 48px;
  width: 200px;
  border: none;
  cursor: ${props => (props.disabled ? "arrow" : "pointer")};
  transition: 0.3s box-shadow;

  &:hover {
    ${props =>
      props.disabled
        ? "none"
        : "box-shadow: 0 12px 24px 0 rgba(131, 90, 238, 0.45)"};
  }

  &:focus {
    outline: none;
  }
`
interface ButtonProps {
  onClick?: () => void
  disabled?: boolean
}
const Button: React.FC<ButtonProps> = props => {
  const { children } = props

  return <StyledButton {...props}>{children}</StyledButton>
}

export default Button
