import * as React from 'react'
import { SimpleMenu, SimpleMenuProps } from '@rmwc/menu'

import '@material/menu/dist/mdc.menu.css'
import '@material/menu-surface/dist/mdc.menu-surface.css'
import '@material/list/dist/mdc.list.css'

export const Menu: React.FC<SimpleMenuProps> = props => {
  return <SimpleMenu {...props} />
}
