const moment = require("moment")

const VERSION = "LIVE"

let FUNCTION_PATH
let STRIPEKEY
let NOW

if (VERSION === "LIVE") {
  FUNCTION_PATH = "https://us-central1-sublist-vgraphql.cloudfunctions.net"
  STRIPEKEY = "pk_live_PBFpceI7zdMjyv54IzsCPRL1"
  NOW = moment().valueOf()
} else {
  FUNCTION_PATH = "http://localhost:5001/sublist-vgraphql/us-central1"
  STRIPEKEY = "pk_test_2axeU2PY0js5zVHWwNxe73Oy"
  NOW = moment().valueOf()
}

exports.FUNCTION_PATH = FUNCTION_PATH
exports.STRIPEKEY = STRIPEKEY
exports.DATE_FORMAT = "MMMM Do, YYYY"
exports.NOW = NOW
exports.VERSION = VERSION
