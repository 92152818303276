import * as React from "react"
import styled from "styled-components"
import { Link } from "@reach/router"
import { useCurrentUser } from "../hooks/useCurrentUser"
import { HeaderSignedIn } from "./HeaderSignedIn"
import { HeaderSignedOut } from "./HeaderSignedOut"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Container } from "./Container"

const Wrapper = styled.div`
  position: relative;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  height: 144px;
  max-height: 144px;
  background-color: ${props => props.theme.primary};
  background-position-x: center;
  background-repeat: repeat-x;
  color: white;
  width: 100% !important;
  overflow: hidden;
  margin-bottom: 16px;

  a,
  a:visited {
    color: white;
  }
`

const HeaderRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  z-index: 2;
`

const HeaderImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const ImageLink = styled.img`
  cursor: pointer;
  width: 100%;
`

const Header: React.FC<{ className?: string }> = ({ className }) => {
  const { currentUser } = useCurrentUser()

  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 206, height: 26) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      headerImage: file(relativePath: { eq: "deadpool_header.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  {
  }
  return (
    <Wrapper>
      <HeaderImage
        fluid={data.headerImage.childImageSharp.fluid}
        Tag="header"
      />
      <Container>
        <HeaderRow>
          <Link to="/">
            <Img
              fixed={data.logoImage.childImageSharp.fixed}
              alt="image"
              fadeIn={false}
              loading="eager"
            />
          </Link>

          {currentUser && <HeaderSignedIn />}
          {!currentUser && <HeaderSignedOut />}
        </HeaderRow>
      </Container>
    </Wrapper>
  )
}

export default Header
