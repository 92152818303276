import * as React from "react"
import { CurrentUserContext } from "./CurrentUserContext"

interface User {
  token?: string
}

export const useCurrentUser = () => {
  const { currentUser, loading } = React.useContext(CurrentUserContext)

  return {
    currentUser,
    loading,
    currentUserContext: {
      headers: { authorization: `Bearer ${currentUser && currentUser.token}` },
    },
  }
}
