export const theme = {
  // primary: '#3388FF',
  primary: "#3451CC",
  error: "#d03232",
  success: "#7dc135",
  background: "#EEE",
  containerWidth: "1160px",
  grey100: "#FAFAFA",
  grey200: "#e1e1e1",
  grey300: "#dadada",
  grey500: "#ababab",
  grey700: "#666",
  grey900: "#1d1d1d",
}
