import styled from "styled-components"

interface ContainerProps {
  alignStart?: boolean
}

export const Container = styled.div<ContainerProps>`
  margin: 0 ${p => !p.alignStart && "auto"};
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;

  /* TODO: ADD MEDIA QUERY */
  /* padding: 0 24px; */

  @media (max-width: 420px) {
    padding: 0 8px;
  }
`
