import * as React from "react"
import Button from "./Button"
import useFirestore from "../hooks/useFirestore"
import { useSet } from "../hooks/useMutation"
import { Subhead1 } from "./rmwc/Typography"
import * as moment from "moment"
import { PullContext } from "../views/Comic"
import { useCurrentUser } from "../hooks/useCurrentUser"

const ButtonSubscribe = ({ seriesCode, title, currentUser }) => {
  const { pull, loading } = React.useContext(PullContext)

  if (!currentUser) {
    return (
      <Button>
        <Subhead1>Login to add</Subhead1>
      </Button>
    )
  }
  const pullId = `${currentUser.uid}-${seriesCode}`
  console.log(pullId)

  const [createPull, { loading: createLoading }] = useSet({
    collection: "pulls",
    data: {
      active: true,
      covers: {
        default: {
          0: 1,
        },
      },
      seriesCode,
      title,
      uid: currentUser.uid,
      createdOn: moment().valueOf(),
    },
  })

  const [deletePull, { loading: deleteLoading }] = useSet({
    collection: "pulls",
    data: {
      active: false,
      cancelledOn: moment().valueOf(),
    },
  })

  const [recreatePull, { loading: recreateLoading }] = useSet({
    collection: "pulls",
    data: {
      active: true,
      createdOn: moment().valueOf(),
    },
  })

  return (
    <>
      {!pull && (
        <Button disabled={loading} onClick={() => createPull(pullId)}>
          <Subhead1>Subscribe</Subhead1>
        </Button>
      )}

      {pull && !pull.active && (
        <Button disabled={loading} onClick={() => recreatePull(pullId)}>
          <Subhead1>Resubscribe</Subhead1>
        </Button>
      )}

      {pull && pull.active && (
        <Button disabled={loading} onClick={() => deletePull(pullId)}>
          <Subhead1>Cancel</Subhead1>
        </Button>
      )}
    </>
  )
}

export default ButtonSubscribe
