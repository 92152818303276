export {
  Headline1,
  Headline2,
  Subhead1,
  Subhead2,
  Body1,
  Body2,
  BodySmall,
  Caption,
  Warning,
} from './Typography'
