import * as React from "react"
import { firestore } from "../core/firebase"
import { supportsGoWithoutReloadUsingHash } from "history/DOMUtils"

interface MutationProps {
  collection: string
  update?: any
  add?: any
}

const useMutation = (mutation: MutationProps) => {
  const { collection, update, add } = mutation
  let collectionRef = firestore.collection(collection)

  if (add) {
    return collectionRef.add(add).then(() => {
      return { success: true, error: null, loading: false }
    })
  }

  if (update) {
    const updateWithoutId = Object.keys(update).reduce((object, key) => {
      if (key != "id") {
        object[key] = update[key]
      }
      return object
    }, {})

    return collectionRef
      .doc(update.id)
      .update(updateWithoutId)
      .then(() => {
        return { success: true, error: null, loading: false }
      })
      .catch(error => {
        return { success: false, error: error.message, loading: false }
      })
  }

  return { loading: true }
}

type UseMutationType = (mutation: {
  collection: string
  data?: any
}) => [(id?: string) => any, { loading: boolean; error: string }]

export const useAdd: UseMutationType = mutation => {
  const { collection, data } = mutation
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  let collectionRef = firestore.collection(collection)

  const executeMutation = async () => {
    setLoading(true)

    return await collectionRef
      .add(data)
      .catch(error => {
        setLoading(false)
        setError(error.message)
        return { success: false }
      })
      .then(docRef => {
        console.log("doc ref:", docRef)

        setLoading(false)
        setError(null)
        return { success: true, id: docRef.id }
      })
  }

  return [executeMutation, { loading, error }]
}

export const useSet: UseMutationType = mutation => {
  const { collection, data } = mutation
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  let collectionRef = firestore.collection(collection)

  const executeMutation = async (id: string) => {
    setLoading(true)

    return await collectionRef
      .doc(id)
      .set(data, { merge: true })
      .catch(error => {
        setLoading(false)
        setError(error.message)
        return { success: false }
      })
      .then(() => {
        setLoading(false)
        setError(null)
        return { success: true }
      })
  }

  return [executeMutation, { loading, error }]
}

export const useUpdate: UseMutationType = mutation => {
  const { collection, data } = mutation
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  let collectionRef = firestore.collection(collection)

  const executeMutation = (id: string) => {
    setLoading(true)

    return collectionRef
      .doc(id)
      .update(data)
      .catch(error => {
        setLoading(false)
        setError(error.message)
        return { success: false }
      })
      .then(() => {
        setLoading(false)
        setError(null)
        return { success: true, id: id }
      })
  }

  return [executeMutation, { loading, error }]
}

export const useDelete: UseMutationType = mutation => {
  const { collection } = mutation
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  let collectionRef = firestore.collection(collection)

  const executeMutation = async (id: string) => {
    console.log("deleting ", id)
    setLoading(true)

    return await collectionRef
      .doc(id)
      .delete()
      .catch(error => {
        setLoading(false)
        setError(error.message)
        return { success: false }
      })
      .then(() => {
        setLoading(false)
        setError(null)
        return { success: true }
      })
  }

  return [executeMutation, { loading, error }]
}

export default useMutation
